<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <!-- <el-header>
      <div>
        <img v-show="!isMobile" class="left-logo" src="../assets/logosmall.png" alt="" />
        <i :class="isCollapse?'iconfont icontoggle-left':'iconfont icontoggle-left menu-collapse'" @click="toggleCollapse"></i>
        <span>汇工富管理平台</span>
      </div>

    </el-header> -->
    <!-- <el-container> -->
      <!-- 左侧菜单区域 -->
      <el-aside :width="isCollapse?collapseWidth:openWidth">
        <!-- <div class="toggle-button" @click="toggleCollapse" >|||</div> -->
        <!-- 侧边栏菜单 -->
        <el-menu ref="menu" @open="handleOpen" background-color="#304156" text-color="#fff" active-text-color="#4699F2" unique-opened="true" :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
          <el-menu-item index="999" @click.native="tohome" :style="homeFont" >
                    <i class="el-icon-aim"></i>
                    <span class="el-menu-item-name" style="margin-left: 0">系统首页</span>
                </el-menu-item>
          <el-submenu :index="item.MenuName" v-for="item in menuList" :key="item.MenuName">
            <template slot="title">
              <i :class="item.IconSet"></i>
              <span class="menu-left">{{ item.MenuName }}</span>
            </template>
            <el-menu-item :index="subItem.Path" v-for="subItem in item.ChildrenMenuList" :key="subItem.MenuName" @click="saveNavState(subItem.Path,item.MenuName,subItem.MenuName)">
              <i class="el-icon-menu"></i>
              <span>{{ subItem.MenuName }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <div class="right-div">
        <el-header>
      <div class="logo-div">
        <i :class="isCollapse?'iconfont icontoggle-left':'iconfont icontoggle-left menu-collapse'" @click="toggleCollapse"></i>
        <img v-show="!isMobile" class="left-logo" src="../assets/logosmall.png" alt="" />
        <span :style="(isCollapse && isMobile)  || isMobile?'display:none;':''">登海先锋种业SRM平台</span>
      </div>
      <div class="right-menu-div">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-circle-check" @click.native="changePassword">密码修改</el-dropdown-item>
            <el-dropdown-item icon="el-icon-circle-check" @click.native="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
        </el-header>
        
      <!-- 主体区域 -->
      <el-main>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-if="firstItem!=''" @click.native="tohome">首页</el-breadcrumb-item><!--:to="{ path: '/home' }"-->
          <el-breadcrumb-item v-if="firstItem!=''">{{firstItem}}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="secondItem!=''">{{secondItem}}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 路由占位符 -->
        <router-view />
        <!-- <transition name="el-fade-in">
         
        </transition> -->
      </el-main>
      </div>

    <!-- </el-container> -->
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menuList: [],
      // 左侧菜单是否折叠
      isCollapse: false,
      activePath: "",
      userName: "默认用户",
      isMobile: false,
      openWidth: '200px',
      collapseWidth: '64px',
      firstItem:'',
      secondItem:'',
      keyIndex:0,
      homeFont:'color:#4699F2;',
    };
  },
  watch: {
        $route () {
          //console.log(this.$route.path);
            if (this.$route.path === '/welcome') {
                this.$refs.menu.close(this.keyIndex);
                this.homeFont='color:#4699F2;'
            }
        }
    },
  created() {
    var res = this.$common._isMobile();
    if (res == null || res == '') {
      this.isMobile = false;
      window.sessionStorage.setItem('isMobile', false);
    }
    else {
      this.isMobile = true;
      window.sessionStorage.setItem('isMobile', true);
    }
    //当用name 路由形式传递参数时，使用params
    //let roleid = this.$route.params.roleid;
    // let roleid = this.$route.query.roleid;
    let roleid = window.sessionStorage.getItem("roleId");
    this.getMenuList(roleid);
    this.activePath = window.sessionStorage.getItem("activePath");
    this.userName = window.sessionStorage.getItem("userName");
    var isMobileTemp = window.sessionStorage.getItem("isMobile");
    console.log('isMobile:'+isMobileTemp);
    if (isMobileTemp == 'true') {
      
      this.collapseWidth = '0px';
      this.isCollapse=true;
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

  },
  methods: {
    handleOpen (key) {
        console.log(key)
            this.keyIndex = key;
        },
    tohome(){
      this.firstItem='';
      this.secondItem='';
      this.activePath='';
      window.sessionStorage.setItem("activePath", '');
      this.$router.push('/home').catch(err => {
          console.log(err);
        });
    },
    logout() {
      // 清空sessionStorge
      window.sessionStorage.clear();
      // 路由导航到登录页
      this.$router.push("/login");
    },
    changePassword() {
      this.$router.push("/changepassword");
    },

    async getMenuList(roleid) {
      await this.$http.post("Menu/GetMenuList", { roleid: roleid }).then(
        (res) => {
          if (res.data.Code !== "200")
            return this.$message.error(res.data.Message);
          this.menuList = res.data.Obj;
          //   console.log(res.data.Obj);
        },
        function (res) {
          this.$message.error("远程获取数据失败");
        }
      );
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存激活菜单的值
    saveNavState(activePath,item,subItem) {
      this.homeFont='';
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
      this.firstItem=item;
      this.secondItem=subItem;
      if (this.isMobile == true) {
        this.isCollapse = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.left-logo {
  margin-left: 20px;
}
.el-header {
  // background-color: #576f8b;
  background-color: fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #576f8b;
  font-size: 20px;
  font-weight: 600;
  position:fixed;
  top:0;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    img {
      height: 40px;
      height: 26px;
    }
    span {
      margin-left: 15px;
    }
  }
}
.logo-div{
  flex:1;
}
.right-menu-div{
  width: 350px;

}
.el-aside {
  background-color: #304156;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  // eaedf2
  background-color: #eaedf2;
  margin-top: 60px;
}
.home-container {
  height: 100%;
}
.right-div{
   display: flex;
   flex-direction: column;
   width: 100%;
}
.toggle-button {
  // #485164
  background-color: #485164;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.menu-left {
  margin-left: 10px;
}
.menu-collapse {
  transform: rotate(180deg);
}
@media screen and (max-width: 500px) {
  .el-pagination {
    width: 100% !important;
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .el-pagination__total {
      width: 160px !important;
      height: 50px !important;
      line-height: 50px !important;
    }
    .el-pagination__sizes {
      width: 160px !important;
      height: 50px !important;
      line-height: 50px !important;
    }
    .btn-prev,
    .btn-next {
      width: 50px !important;
      padding: 0;
    }
    .el-pager {
      width: 200px !important;
    }
    .el-pagination__jump {
      width: 100px !important;
      height: 50px !important;
      line-height: 50px !important;
      margin: 0;
      margin-left: calc(100% / 2 - 120px);
    }
  }
}
</style>