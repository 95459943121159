<template>
    <div>
       欢迎您登录——登海先锋种业SRM平台！
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>


</style>