<template>
  <div>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-form :model="addDialogForm" :rules="addDialogFormRules" ref="addDialogFormRef" label-width="120px" class="dialog_form">
        <el-form-item label="原始密码" prop="oldPassword">
          <el-input v-model="addDialogForm.oldPassword" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新的密码" prop="newPassword">
          <el-input v-model="addDialogForm.newPassword" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="ackPassword">
          <el-input v-model="addDialogForm.ackPassword" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div style="margin-left:120px;">
        <el-button type="primary" @click="saveAdd">保 存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {

    return {
      RoleName:window.sessionStorage.getItem("rolename"),
      addDialogForm: {
        oldPassword: "",
        newPassword: "",
        code: "",
      },
      addDialogFormRules: {
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        newPassword: [
          { required: true, message: "请输入新的密码", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        ackPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {

  },
  methods: {

    change(e) {
      this.$forceUpdate();
    },


    saveAdd() {
      if (this.addDialogForm.oldPassword.trim() === "" || this.addDialogForm.newPassword.trim() === "" || this.addDialogForm.ackPassword.trim() === "")
        return this.$message.error("密码不能为空");
      if (this.addDialogForm.newPassword.trim() !== this.addDialogForm.ackPassword.trim())
        return this.$message.error("两次密码不一致");
      this.$refs.addDialogFormRef.validate((valid) => {
        if (!valid) return this.$message.error("关键信息不能为空");
        this.submitAdd();
      });
    },
    async submitAdd() {
      var code=window.sessionStorage.getItem("id");
      var paramVar = this.$qs.stringify({
        oldpassword: this.addDialogForm.oldPassword,
        newpassword: this.addDialogForm.newPassword,
        code:code,
      });
      var url="";
      url="User/ChangePassword/"
      await this.$http.post(url, paramVar).then(
        (res) => {
          if (res.data.Code !== "200") {
            return this.$message.error(res.data.Message);
          }
          this.$refs.addDialogFormRef.resetFields();
          this.$message.success(res.data.Message);
          this.logout();
        },
        (res) => {
          this.$message.error("远程操作失败或网络问题");
        }
      );
    },
    logout() {
      // 清空sessionStorge
      window.sessionStorage.clear();
      // 路由导航到登录页
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
</style>